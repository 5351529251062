<template>
    <div class="vip-content addressForGoods">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            收票信息管理
        </div>
        <div class="vip-content-inner">
            <div class="add-address-con" v-if="edit">
                <div class="login-register-form">
                    <el-form label-position="top" ref="addressForm" :model="addressForm" :rules="addressRules">
                        <el-form-item label="收票人姓名：" prop="name">
                            <div class="con">
                                <el-input v-model="addressForm.name" placeholder="请输入收票人姓名"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="收票地址：" prop="address">
                            <div class="flex">
                                <el-select @change="provinceChange" v-model="addressForm.provinceName" placeholder="请选择省">
                                    <el-option
                                        v-for="(item,index) in provincelist"
                                        :key="item.code"
                                        :label="item.provinceName"
                                        :value="item.provinceName">
                                    </el-option>
                                </el-select>
                                <el-select @change="cityChange" v-model="addressForm.cityName" placeholder="请选择市">
                                    <el-option
                                        v-for="(item,index) in citylist"
                                        :key="item.code"
                                        :label="item.cityName"
                                        :value="item.cityName">
                                    </el-option>
                                </el-select>
                                <el-select @change="districtChange" v-model="addressForm.districtName" placeholder="请选择区">
                                    <el-option
                                        v-for="item in districtlist"
                                        :key="item.code"
                                        :label="item.districtName"
                                        :value="item.districtName">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="con">
                                <el-input v-model="addressForm.address" placeholder="请输入街道地址"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="手机号：" prop="phone">
                            <div class="con">
                                <el-input type="number" v-model="addressForm.phone" placeholder="请输入手机号"></el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="g-btns">
                        <div class="g-btn small" @click="saveAddress()">
                            <img src="@/assets/img/i-save.png" alt="">
                            保存
                        </div>
                        <div class="g-btn small gray" @click="clearForm()">
                            <img src="@/assets/img/i-cancel.png" alt="">
                            取消
                        </div>
                    </div>
                </div>
            </div>
            <template v-else>
                <div class="address-list" v-if="addressList.length>0">
                    <div class="item" v-for="(item,index) in addressList" :key="index">
                        <div class="radio">
                            <p>{{ item.username }}，{{ item.provinceName }}，{{ item.cityName }}，{{ item.districtName }}{{ item.address }}，{{ item.phone }}</p>
                        </div>
                        <div class="tools">
                        <span class="blue"  @click="clickedit(item)">
                             <img src="@/assets/img/edit-blue.png" alt="">
                            编辑</span>
                            <span @click="showDel = true,addressId=item.id" class="red">
                            <img src="@/assets/img/del.png" alt="">
                            删除</span>
                            <span class="gray" style="color: #ea6100" v-if="item.isDefault==1"><img src="@/assets/img/def-active.png" alt="">默认地址</span>
                            <span class="gray" @click="setdefault(item.id)" v-else><img src="@/assets/img/def.png" alt="">默认地址</span>
                        </div>
                    </div>
                </div>
                <div v-else class="paddingTB20px text_cen">暂未添加收票地址</div>
                <div class="g-btn add-btn" @click="edit = true">
                    <img src="@/assets/img/add.png" alt="">
                    添加新地址
                </div>
            </template>


        </div>
        <delPop v-if="showDel" @close="showDel = false" @confirm="deleteAddress"/>
    </div>
</template>

<script>
import delPop from "@/components/delPop.vue";
import {
    getregionList,
    billaddressList,
    billAddresssave,
    billAddressupdate,
    billAddressdelete,
    billAddressDefault,
    AddressDefault
} from "@/api/account";

export default {
    name: "addressForBill",
    components: {delPop},
    data() {
        return {
            showDel: false,
            addressForm: {
                name:'',
                phone:"",
                provinceName:'',
                cityName:'',
                districtName:"",
                address:""
            },
            addressRules: {
                name: [{required: true, trigger: 'blur', message: '必填项'}],
                address: [{required: true, trigger: 'blur', message: '必填项'}],
                phone: [{required: true, trigger: 'blur', message: '必填项'}],
            },
            edit:false,
            addressList:[], // 收票地址
            addressId:"",

            provincelist:[],
            citylist:[],
            districtlist:[],
            regionCode:"", // 行政区划编号
        }
    },
    mounted(){
        // 有token并且未过期
        if(localStorage.getItem('Token') && !this.$utils.isTokenExpired()){
            // console.log(provice)
            this.getbilladdressList()
            this.getCity() // 获取省市区列表
        }else{
            this.$router.push({path:'/home'})
        }
    },
    methods:{
        // 监听省份选择
        provinceChange(e){
            console.log(e)
            this.provincelist.forEach(i=>{
                if(i.provinceName==e){
                    this.citylist = i.children
                }
            })

            this.districtlist=[]
            this.regionCode=''
            this.addressForm.cityName=''
            this.addressForm.districtName=''
        },
        // 监听城市选择
        cityChange(e){
            console.log(e)
            this.citylist.forEach(i=>{
                if(i.cityName==e){
                    this.districtlist = i.children
                }
            })
            this.regionCode=''
            this.addressForm.districtName=''
        },
        // 监听地区选择
        districtChange(e){
            console.log(e)
            this.districtlist.forEach(i=>{
                if(i.districtName==e){
                    this.regionCode = i.code
                }
            })
        },
        // 点击编辑
        clickedit(item){
            this.addressId=item.id
            this.addressForm= {
                name:item.username,
                phone:item.phone,
                provinceName:item.provinceName,
                cityName:item.cityName,
                districtName:item.districtName,
                address:item.address
            }
            this.provincelist.forEach(i=>{
                if(i.provinceName==item.provinceName){
                    this.citylist = i.children
                    this.citylist.forEach(j=>{
                        if(j.cityName==item.cityName){
                            this.districtlist = j.children
                            this.districtlist.forEach(k=>{
                                if(k.districtName==item.districtName){
                                    this.regionCode = k.code
                                }
                            })
                        }
                    })
                }
            })
            console.log(this.citylist)
            console.log(this.districtlist)
            this.edit = true
        },
        // 设置默认地址
        setdefault(id){
            console.log('设置默认')
            billAddressDefault({id:id}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "操作成功",
                        duration: 2000
                    });
                    this.getbilladdressList() // 重新获取地址列表
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },
        //保存地址
        saveAddress(){
            if(this.addressId){ // 编辑
                this.addAddress(this.addressId)
            }else{ // 新增
                this.addAddress()
            }
        },
        // 清空表单
        clearForm(){
            this.edit = false
            this.addressForm={
                name:'',
                phone:"",
                provinceName:'',
                cityName:'',
                districtName:"",
                address:""
            }
            this.addressId=''
            this.regionCode=''
        },


        //  -------------------------接口请求
        getbilladdressList(){ // 获取收发票地址列表
            let customerId=localStorage.getItem('customId')
            billaddressList({customerId:customerId}).then((res) => {
                if (res.data.code==200) {
                    this.addressList=res.data.data
                    this.addressList.forEach(i=>{
                        let array=[]
                        console.log(i.regionName)
                        array=i.regionName.split('-')
                        i.provinceName=array[0]
                        i.cityName=array[1]
                        i.districtName=array[2]
                        console.log(array)

                    })
                }

            })
        },
        // 获取行政区划
        getCity(){
	        console.log(this.$lazylist)
	        this.provincelist=this.$lazylist
            // getregionList({}).then((res) => {
            //     if (res.data.code==200) {
            //         let data=res.data.data.slice(1)
            //         console.log(data)
            //         this.provincelist=data
            //     }
            // })
        },
        // 新增地址
        addAddress(id){
            this.$refs.addressForm.validate(valid => {
                if (valid) {
                    if (!(/^1[3456789]\d{9}$/.test(this.addressForm.phone))) {
                        this.$message({
                            type: 'warning',
                            message: '手机号格式不正确！',
                            duration: 1500
                        });
                        return
                    }
                    if (!this.regionCode) {
                        this.$message({
                            type: 'warning',
                            message: '请选择省市区！',
                            duration: 1500
                        });
                        return
                    }
                    let isDefault= this.addressList.length==0?1:0
                    let param={
                        customerId:localStorage.getItem('customId'), // 登录用户对应的客户的ID
                        username:this.addressForm.name,
                        phone:this.addressForm.phone,
                        address:this.addressForm.address,
                        regionCode:this.regionCode,
                        isDefault:isDefault // 1是0否
                    }
                    let url=billAddresssave
                    if(id){ // 编辑
                        param.id=id
                        url=billAddressupdate
                    }else{
                        url=billAddresssave
                    }
                    url(param).then((res) => {
                        if (res.data.code==200) {
                            this.$message({
                                type: 'success',
                                message: "保存成功",
                                duration: 2000
                            });
                            this.getbilladdressList() // 重新获取地址列表

                            this.clearForm()
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                duration: 2000
                            });
                        }

                    })
                }
            })

        },
        deleteAddress(){
            console.log(this.addressId)
            billAddressdelete({ids:this.addressId}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "删除成功",
                        duration: 2000
                    });
                    this.getbilladdressList() // 重新获取地址列表
                    this.showDel=false
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
                this.addressId=''

            })
        },


    },
}
</script>

<style lang="scss">
//.addressForGoods.vip-content{
//	.el-select-dropdown .el-popper{
.el-scrollbar .el-select-dropdown__wrap.el-scrollbar__wrap{
    margin-bottom: -15px !important;
    margin-right: -5px !important;
}
//}

//}

</style>
<style scoped>

</style>
