<template>
    <div class="m-pop del-pop">
        <div class="pop-con" style="max-width: 410px;">
            <div class="title">
                <h3></h3>
                <div class="close" @click="$emit('close')"></div>
            </div>
            <div class="inner-con">
                <h3>确定要删除吗?</h3>
                <div class="g-btns tar">
                    <div class="g-btn small white" @click="$emit('close')">取消</div>
                    <div class="g-btn small" @click="$emit('confirm')">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "delPop",
    data() {
        return {
            form: {}
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
