
import qs from 'qs'
import request from '@/utils/request'

// 用户信息
export function userDetail(params) {
    return request({
        url: '/blade-user/customer/detail-by-userId',
        method: 'get',
        params: params,
        // data: qs.stringify(params),
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', //application/json
        //     'Authorization':"Basic c3dvcmQ6c3dvcmRfc2VjcmV0",
        //     'Tenant-Id':"000000",
        // }
    })
}
// 修改用户信息
export function updateUserDetail(params) {
    return request({
        url: '/blade-user/customer/update',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}

// 获取行政区信息
export function getregionList(params) {
    return request({
        url: '/blade-system/region/lazy-list',
        method: 'get',
        params: params,
    })
}
// 获取收货地址
export function addressList(params) {
    return request({
        url: '/blade-user/customer-delivery/list',
        method: 'get',
        params: params,
    })
}
// 新增收货地址
export function Addresssave(params) {
    return request({
        url: '/blade-user/customer-delivery/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
// 编辑收货地址
export function Addressupdate(params) {
    return request({
        url: '/blade-user/customer-delivery/update',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
// 删除收货地址
export function Addressdelete(params) {
    return request({
        url: '/blade-user/customer-delivery/remove',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}
// 默认收货地址
export function AddressDefault(params) {
    return request({
        url: '/blade-user/customer-delivery/default',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}


// 获取收发票地址
export function billaddressList(params) {
    return request({
        url: '/blade-user/customer-receipt/list',
        method: 'get',
        params: params,
    })
}
// 新增收发票地址
export function billAddresssave(params) {
    return request({
        url: 'blade-user/customer-receipt/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
// 编辑收发票地址
export function billAddressupdate(params) {
    return request({
        url: '/blade-user/customer-receipt/update',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
// 删除收发票地址
export function billAddressdelete(params) {
    return request({
        url: '/blade-user/customer-receipt/remove',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}
// 默认发票地址
export function billAddressDefault(params) {
    return request({
        url: '/blade-user/customer-receipt/default',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}

// 获取发票信息列表
export function billInfoList(params) {
    return request({
        url: '/blade-user/customer-invoice/list',
        method: 'get',
        params: params,
    })
}
// 新增发票信息
export function billInfosave(params) {
    return request({
        url: 'blade-user/customer-invoice/save',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
// 编辑发票信息
export function billInfoupdate(params) {
    return request({
        url: '/blade-user/customer-invoice/update',
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}
// 删除发票信息
export function billInfodelete(params) {
    return request({
        url: '/blade-user/customer-invoice/remove',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}
// 默认发票信息
export function billInfodefault(params) {
    return request({
        url: '/blade-user/customer-invoice/default',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}


// 修改密码
export function updatePassword(params) {
    return request({
        url: 'blade-user/update-password',
        method: 'post',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    })
}

// 获取消息通知列表
export function noticeList(params) {
    return request({
        url: '/blade-desk/notice/list',
        method: 'get',
        params: params,
    })
}
// 获取消息通知详情
export function noticeDetail(params) {
    return request({
        url: '/blade-desk/notice/detail',
        method: 'get',
        params: params,
    })
}

// 获取用户消息记录列表
export function messageRecordPage(params) {
    return request({
        url: '/blade-desk/message-record/page',
        method: 'get',
        params: params,
    })
}
// 获取用户消息记录详情
export function messageRecordDetail(params) {
    return request({
        url: '/blade-desk/message-record/detail',
        method: 'get',
        params: params,
    })
}





